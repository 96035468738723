import * as Sentry from '@sentry/nuxt';

if (useRuntimeConfig().public.sentry.dsn) {
    Sentry.init({
        // If set up, you can use your runtime config here
        // dsn: useRuntimeConfig().public.sentry.dsn,
        dsn: useRuntimeConfig().public.sentry.dsn,
        environment: useRuntimeConfig().public.sentry.environment,

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
}
